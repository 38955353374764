<template>
	
	<div class="-ml100 -mt50 -s1-1">
		<div class="-mt20">
			<text class="column">手机号码：</text>
			<text>{{view.mobile}}</text>
		</div>
		<div class="-mt20">
			<text class="column">姓名：</text>
			<text>{{view.name}}</text>
		</div>
		<div class="-mt20">
			<text class="column">所在部门：</text>
			<text>{{view.departName}}</text>
		</div>
		<div class="-mt20">
			<text class="column">所属角色：</text>
			<text>{{view.roleName}}</text>
		</div>
		<div class="-mt20">
			<text class="column">最后登录时间：</text>
			<text>{{view.lastLoginTime}}</text>
		</div>
		<div class="-mt20">
			<text class="column">最后登录IP：</text>
			{{view.lastLoginIP}}
		</div>
	</div>
</template>

<script>
import { reactive } from 'vue'
import _edit  from '@/assets/js/edit'

export default {
	props: [],
	emits: [],
	setup(props, context) {
		return {
			context,
			view: reactive({}),
		}
	},
	
	mounted: function(){
		this.init()
	},
	methods: {
		//初始化
		init: function(){
			this._post('/System/User/mine', {}, (res)=>{
				this.view = res.data
				this.$forceUpdate()
			})
		}
	}
}
</script>

<style scoped="scoped">
.column { display: inline-block; width: 100px; text-align: right; }
</style>